import { Button, Drawer, Row, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type { StrategyItem } from '../types';
import { ENUM_WAVE_STRATEGY_TYPE, WaveStrategySettingModalStore } from './waveStrategySettingModalStore';
import { ManualSetting } from './manualSetting/manualSetting';
import styles from './waveStrategySettingModal.less';
import { RenderByCondition } from 'egenie-common';
import { AutomaticSetting } from './automaticSetting/automaticSetting';

export interface WaveStrategySettingModalProps {
  activeStrategyItem?: StrategyItem;
  callback: (item: Partial<StrategyItem>) => void;
  onCancel: () => void;
}

export const WaveStrategySettingModal: React.FC<WaveStrategySettingModalProps> = observer((props) => {
  const waveStrategySettingModalStore = React.useMemo(() => new WaveStrategySettingModalStore(props), []);
  const {
    isSubmit,
    handleSubmit,
    activeWaveStrategyType,
    handleActiveWaveStrategyTypeChange,
    manualSettingStore,
    automaticSettingStore,
  } = waveStrategySettingModalStore;

  const { onCancel } = props;
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      footer={(
        <Row justify="end">
          <Space size="small">
            <Button onClick={onCancel}>
              取消
            </Button>
            <Button
              loading={isSubmit}
              onClick={handleSubmit}
              type="primary"
            >
              保存
            </Button>
          </Space>
        </Row>
      )}
      maskClosable={false}
      onClose={onCancel}
      title="波次策略"
      visible
      width={1100}
      zIndex={1031}
    >
      <Row className={styles.mainContainer}>
        <div
          className={styles.leftContainer}
          onClick={(event) => handleActiveWaveStrategyTypeChange((event.target as HTMLElement).dataset.type)}
        >
          <section
            className={activeWaveStrategyType === ENUM_WAVE_STRATEGY_TYPE.manualSetting.value ? styles.active : undefined}
            data-type={ENUM_WAVE_STRATEGY_TYPE.manualSetting.value}
          >
            {ENUM_WAVE_STRATEGY_TYPE.manualSetting.label}
          </section>
          <section
            className={activeWaveStrategyType === ENUM_WAVE_STRATEGY_TYPE.automaticSetting.value ? styles.active : undefined}
            data-type={ENUM_WAVE_STRATEGY_TYPE.automaticSetting.value}
          >
            {ENUM_WAVE_STRATEGY_TYPE.automaticSetting.label}
          </section>
        </div>
        <div className={styles.rightContainer}>
          <RenderByCondition show={activeWaveStrategyType === ENUM_WAVE_STRATEGY_TYPE.manualSetting.value}>
            <ManualSetting manualSettingStore={manualSettingStore}/>
          </RenderByCondition>
          <RenderByCondition show={activeWaveStrategyType === ENUM_WAVE_STRATEGY_TYPE.automaticSetting.value}>
            <AutomaticSetting automaticSettingStore={automaticSettingStore}/>
          </RenderByCondition>
        </div>
      </Row>
    </Drawer>
  );
});


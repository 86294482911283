import styles from './selectGoodsModal.less';
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Input, Modal, Select, Space } from 'antd';
import { EgGrid } from 'egenie-utils';
import type { SelectGoodsModalStore } from './selectGoodsModalStore';
import { ENUM_FIND_BY_CONDITIONS_KEY } from './selectGoodsModalStore';
import { egeniePcTheme } from 'egenie-common';

interface SelectGoodsModalProps {
  selectGoodsModalStore: SelectGoodsModalStore;
}

export const SelectGoodsModal: React.FC<SelectGoodsModalProps> = observer(({ selectGoodsModalStore }) => {
  const {
    egGridBottomStore,
    egGridTopStore,
    onBatchAddClick,
    findByConditionsKey,
    findByConditionsValue,
    handleFindByConditionsKeyChange,
    handleFindByConditionsValueChange,
    queryFindByConditions,
    visible,
    handleClose,
    handleSubmit,
  } = selectGoodsModalStore;

  return (
    <Modal
      centered
      maskClosable={false}
      okText="保存"
      onCancel={handleClose}
      onOk={handleSubmit}
      title="选择商品"
      visible={visible}
      width={960}
      zIndex={1032}
    >
      <>
        <div className={styles.header}>
          <Space>
            <Input.Group compact>
              <Select
                onChange={handleFindByConditionsKeyChange}
                options={Object.values(ENUM_FIND_BY_CONDITIONS_KEY)}
                style={{ width: 80 }}
                value={findByConditionsKey}
              />
              <Input
                allowClear
                onChange={handleFindByConditionsValueChange}
                onPressEnter={queryFindByConditions}
                style={{ width: 200 }}
                value={findByConditionsValue}
              />
            </Input.Group>
            <Button
              onClick={queryFindByConditions}
              type="primary"
            >
              查询
            </Button>
          </Space>
          <Button onClick={onBatchAddClick}>
            批量添加
          </Button>
        </div>
        <div style={{ height: 260 }}>
          <EgGrid store={egGridTopStore}/>
        </div>
        <div style={{ height: 260 }}>
          <EgGrid store={egGridBottomStore}/>
        </div>
        <div style={{ marginTop: egeniePcTheme.spacing['egenie-spacing-xs'] }}>
          共&nbsp;
          <a>
            {egGridBottomStore.rows.length}
          </a>
          &nbsp;条
        </div>
      </>
    </Modal>
  );
});


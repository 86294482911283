import { SelectGoodsModalStore } from './selectGoodsModal/selectGoodsModalStore';
import type { GoodsItem } from './types';
import { ImgFormatter, MainSubStructureModel } from 'egenie-utils';
import React from 'react';
import { action, computed } from 'mobx';
import _ from 'lodash';
import { Button } from 'antd';

export class GoodsDisplayStore {
  public selectGoodsModalStore: SelectGoodsModalStore = new SelectGoodsModalStore();

  @action public handleDeleteItem = (row: GoodsItem) => {
    this.mainSubStructureModel.gridModel.rows = this.mainSubStructureModel.gridModel.rows.filter((item) => item.id !== row.id);
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          formatter: ({ row }: { row: GoodsItem; }) => {
            return (
              <Button
                onClick={() => this.handleDeleteItem(row)}
                type="link"
              >
                删除
              </Button>
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => {
            return <ImgFormatter value={row.pic}/>;
          },
        },
        {
          key: 'productNo',
          name: '商品编码',
        },
        {
          key: 'skuNo',
          name: 'sku编码',
        },
        {
          key: 'colorType',
          name: '颜色',
        },
        {
          key: 'sizeType',
          name: '尺码',
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      showSelectedTotal: false,
      showRefresh: false,
      showReset: false,
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: false,
      showPager: false,
    },
    hiddenSubTable: true,
    api: { onQuery: () => Promise.reject() },
  });

  @action public handleInit = (rows: GoodsItem[]) => {
    this.mainSubStructureModel.gridModel.rows = rows || [];
  };

  @computed
  public get params(): string[] {
    return this.mainSubStructureModel.gridModel.rows.map((item) => item.id);
  }

  @computed
  public get batchDeleteGoodsDisable(): boolean {
    return this.mainSubStructureModel.gridModel.selectRows.length <= 0;
  }

  @action
  public handleChooseGoods = () => {
    this.selectGoodsModalStore.handleOpen((rows) => {
      this.mainSubStructureModel.gridModel.rows = _.uniqBy(this.mainSubStructureModel.gridModel.rows.concat(rows), 'id');
    });
  };

  @action
  public handleBatchDeleteGoods = () => {
    const list = _.difference(this.mainSubStructureModel.gridModel.rows, this.mainSubStructureModel.gridModel.selectRows);
    this.mainSubStructureModel.gridModel.rows = list;
  };
}


export interface StrategyItem {
  id: string;
  waveName: string;
  orderNumber: number;
  loading: boolean;
  auto: boolean;
}

export interface MainItem {
  id?: string;
  waveType?: number;
  wmsPickingOrderNo?: string;
  cartsId?: number;
  warehouseId?: number;
  wmsOrderDetailNum?: number;
  wmsOrderNum?: number;
  pdaDeviceId?: string;
  pdaUserId?: number;
  cartsKindsId?: number;
  wmsPickingOrderState?: number;
  creator?: number;
  createTime?: string;
  lastUpdater?: number;
  lastUpdateTime?: string;
  usable?: boolean;
  tenantId?: number;
  printPickingNum?: number;
  courierId?: number;
  wmsWaveId?: number;
  auto?: boolean;
  orderType?: number;
  printedOrderNumber?: number;
  toPrintOrderNumber?: number;
  inCellNumber?: number;
  orderSortedState?: number;
  groupNoPrintNum?: string;
  latestPrint?: string;
  pmsArrivalRecordId?: number;
  refundSupplierName?: string;
  ownerId?: number;
  waveName?: string;
  orderTotalNum?: number;
  orderPrintedNum?: number;
  ownerName?: string;
  wmsPickingOrderStateStr?: string;
  orderSortedStateStr?: string;
  pdaUserName?: string;
}

export const ENUM_WAVE_STRATEGY_TYPE = {
  normalWave: {
    value: '1',
    label: '普通波次',
  },
  hotWave: {
    value: '2',
    label: '爆款波次',
  },
};

import { message, Modal } from 'antd';
import { renderModal, destroyAllModal, objToDict, formatNumber } from 'egenie-common';
import type { DictData } from 'egenie-common';
import type { BaseData, PaginationData, MainSubStructureModel, NormalProgramme } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { DistributionModal } from './distributionModal';
import { getMainSubStructureModel } from './mainSubStructureModel';
import { getNormalProgramme } from './normalProgramme';
import { ParamsSettingModalStore } from './paramsSettingModal';
import type { StrategyItem, MainItem } from './types';
import { getOwner } from '../../utils';

function getCountDownTime(): number {
  return Date.now() + 1000 * 60;
}

export class Store {
  constructor() {
    this.init();
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.mainSubStructureModel.onQuery();
  }

  public paramsSettingModalStore: ParamsSettingModalStore = new ParamsSettingModalStore();

  @action public init = () => {
    // 货主
    getOwner()
      .then((info) => this.normalProgramme.filterItems.addDict({ ownerId: info }));

    // 波次策略
    request<BaseData<StrategyItem[]>>({ url: '/api/cloud/wms/rest/wave/strategy/listStrategy' })
      .then((info) => this.normalProgramme.filterItems.addDict({
        waveId: (info.data || []).map((item) => ({
          value: `${item.id }`,
          label: item.waveName,
        })),
      }));

    // 拣货状态
    request<DictData>({ url: '/api/cloud/wms/rest/order/picking/get/order/picked/state' })
      .then((info) => this.normalProgramme.filterItems.addDict({ pickingOrderState: objToDict(info) }));

    // 分拣状态
    request<DictData>({ url: '/api/cloud/wms/rest/order/picking/get/order/sorted/state' })
      .then((info) => this.normalProgramme.filterItems.addDict({ orderSortedState: objToDict(info) }));

    // 拣货员
    request<DictData>({
      url: '/api/cloud/wms/rest/order/picking/get/user/picker',
      params: { pickerAll: 1 },
    })
      .then((info) => this.normalProgramme.filterItems.addDict({ pdaUserId: objToDict(info) }));

    // 波次类型
    request<DictData>({ url: '/api/cloud/wms/rest/order/picking/order/type' })
      .then((info) => this.normalProgramme.filterItems.addDict({ wmsPickOrderType: objToDict(info) }));

    // 策略列表
    this.getStrategy();

    // 仓库
    request<Array<{ warehouseName: string; warehouse_id: number; }>>({ url: '/api/cloud/baseinfo/rest/warehouse/listAll' })
      .then((info) => {
        this.normalProgramme.filterItems.addDict({
          warehouseId: (info || []).map((item) => ({
            value: String(item.warehouse_id),
            label: item.warehouseName,
          })),
        });
      });

    // 拣货车
    request<DictData>({ url: '/api/cloud/wms/rest/order/picking/order/cart/list' })
      .then((info) => this.normalProgramme.filterItems.addDict({ cartsId: objToDict(info) }));
  };

  @observable public countDownTime = getCountDownTime();

  private countDownIndex = 0;

  @action public countDownTimeFinish = () => {
    if (this.countDownIndex < this.strategyList.length) {
      this.handleRefreshOrderCount(this.strategyList[this.countDownIndex]);
    }

    this.countDownTime = getCountDownTime();
    this.countDownIndex = (this.countDownIndex + 1) % this.strategyList.length;
  };

  @observable public isShowStrategy = false;

  @observable public activeStrategyItem: StrategyItem = null;

  @action public handleShowStrategy = (item?: StrategyItem) => {
    this.activeStrategyItem = item;
    this.isShowStrategy = true;
  };

  @action public handleCloseStrategy = () => {
    this.activeStrategyItem = null;
    this.isShowStrategy = false;
  };

  @action public handleRefreshOrderCount = (item: StrategyItem) => {
    item.loading = true;
    request<BaseData<number>>({ url: `/api/cloud/wms/rest/wave/strategy/query/wms/order/number/${item.id}` })
      .then((info) => item.orderNumber = formatNumber(info.data))
      .finally(() => item.loading = false);
  };

  @observable public isGetStrategy = false;

  @action public getStrategy = () => {
    this.isGetStrategy = true;
    request<PaginationData<StrategyItem>>({
      url: '/api/cloud/wms/rest/wave/strategy/queryShowList',
      method: 'POST',
      data: {
        page: 1,
        pageSize: 99999,
      },
    })
      .then((info) => {
        this.countDownIndex = 0;
        this.countDownTime = getCountDownTime();
        this.strategyList = (info.data?.list ?? []).map((item) => ({
          ...item,
          loading: false,
        }));
      })
      .finally(() => this.isGetStrategy = false);
  };

  @action public handleGenerateAllWaveStrategy = () => {
    Modal.confirm({
      content: '确认一键生成波次?',
      onOk: () => {
        message.loading({
          content: '正在生成',
          key: 'handleGenerateAllWaveStrategy',
        });

        request({ url: '/api/cloud/wms/rest/wave/auto/create/all' })
          .then(() => {
            this.getStrategy();
          })
          .finally(() => {
            message.destroy('handleGenerateAllWaveStrategy');
          });
      },
    });
  };

  @observable public strategyList: StrategyItem[] = [];

  @action public handleAddStrategy = () => {
    this.handleShowStrategy();
  };

  @action public handleGenerateWave = (item: StrategyItem) => {
    Modal.confirm({
      content: '确认生成?',
      onOk: () => request({
        timeout: 1000 * 60 * 30,
        method: 'POST',
        url: `/api/cloud/wms/rest/wave/strategy/wave/create/${item.id}`,
      })
        .then(() => {
          message.success(`${item.orderNumber}条发货单生成波次成功`);
          item.orderNumber = 0;
          this.mainSubStructureModel.onQuery();
        }),
    });
  };

  @action public handleEditStrategy = (item: StrategyItem) => {
    this.handleShowStrategy(item);
  };

  @action public handleAddOrEditCallback = (item: StrategyItem) => {
    if (item?.id) {
      const idMatchItem = this.strategyList.find((val) => val.id === item.id);
      if (idMatchItem) {
        idMatchItem.waveName = item.waveName;
      } else {
        this.strategyList.push(item);
      }
    }
  };

  @action public handleDeleteStrategy = (item: StrategyItem) => {
    Modal.confirm({
      zIndex: 2000,
      content: '确认删除波次策略吗?',
      onOk: () => request({
        url: '/api/cloud/wms/rest/wave/strategy/delete',
        method: 'POST',
        data: { waveIds: `${item.id}` },
      })
        .then(() => {
          message.success('删除波次策略成功');
          this.strategyList = this.strategyList.filter((val) => val.id !== item.id);
        }),
    });
  };

  @action public handleDistribute = (item: MainItem, isRedistribution: boolean) => {
    renderModal(
      <DistributionModal
        callback={(params) => request({
          url: isRedistribution ? '/api/cloud/wms/rest/order/picking/reset/user' : '/api/cloud/wms/rest/order/picking/bind/user',
          method: 'POST',
          data: {
            wmsPickingOrderId: item.id,
            pdaUserId: params.pdaUserId,
          },
        })
          .then(() => {
            message.success('分配成功');
            destroyAllModal();
            this.mainSubStructureModel.onQuery();
          })}
        onCancel={destroyAllModal}
      />
    );
  };

  @action public handlePatchExamine = (item: MainItem) => {
    Modal.confirm({
      content: '确认批量验货?',
      onOk: () => request({ url: `/api/cloud/wms/rest/order/picking/batch/examine/${item.id}` })
        .then(() => {
          message.success('批量验货成功');
          this.mainSubStructureModel.onRefresh();
        }),
    });
  };

  public mainSubStructureModel: MainSubStructureModel = getMainSubStructureModel(this);

  public normalProgramme: NormalProgramme = getNormalProgramme(this);
}

import { Button, message } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { EgGridModel, ImgFormatter, request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import type { GoodsItem } from '../types';

export const ENUM_FIND_BY_CONDITIONS_KEY = {
  skuNo: {
    value: 'skuNo',
    label: 'sku编码',
  },
  productNo: {
    value: 'productNo',
    label: '商品编码',
  },
};

export class SelectGoodsModalStore {
  @observable public findByConditionsKey = ENUM_FIND_BY_CONDITIONS_KEY.skuNo.value;

  @action public handleFindByConditionsKeyChange = (findByConditionsKey: string) => {
    this.findByConditionsKey = findByConditionsKey;
  };

  @observable public findByConditionsValue = '';

  @action public handleFindByConditionsValueChange = (event) => {
    this.findByConditionsValue = event.target.value;
  };

  @observable public visible = false;

  public egGridTopStore: EgGridModel = new EgGridModel({
    columns: [
      {
        key: 'operate',
        name: '操作',
        frozen: true,
        formatter: ({ row }) => {
          return (
            <Button
              onClick={() => this.onAddGoodsClick(row)}
              type="link"
            >
              添加
            </Button>
          );
        },
      },
      {
        key: 'pic',
        name: '图片',
        formatter: ({ row }) => {
          return <ImgFormatter value={row.pic}/>;
        },
      },
      {
        key: 'productNo',
        name: '商品编码',
      },
      {
        key: 'skuNo',
        name: 'sku编码',
      },
      {
        key: 'colorType',
        name: '颜色',
      },
      {
        key: 'sizeType',
        name: '尺码',
      },
    ].map((item) => ({
      resizable: true,
      sortable: false,
      ...item,
    })),
    rows: [],
    primaryKeyField: 'id',
    sortByLocal: false,
    api: {
      onPageChange: () => {
        this.egGridTopStore.resetAll();
        this.queryFindByConditions();
      },
      onShowSizeChange: () => {
        this.queryFindByConditions();
      },
      onRefresh: () => {
        this.egGridTopStore.resetAll();
        this.queryFindByConditions();
      },
    },
  });

  public egGridBottomStore: EgGridModel = new EgGridModel({
    columns: [
      {
        key: 'operate',
        name: '操作',
        formatter: ({ row }) => {
          return (
            <Button
              onClick={() => this.onDeleteSkuClick(row)}
              type="link"
            >
              删除
            </Button>
          );
        },
      },
      {
        key: 'pic',
        name: '图片',
        formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
      },
      {
        key: 'productNo',
        name: '商品编码',
      },
      {
        key: 'skuNo',
        name: 'sku编码',
      },
      {
        key: 'colorType',
        name: '颜色',
      },
      {
        key: 'sizeType',
        name: '尺码',
      },
    ].map((item) => ({
      resizable: true,
      sortable: false,
      ...item,
    })),
    rows: [],
    primaryKeyField: 'id',
    sortByLocal: false,
    showPager: false,
    showCheckBox: false,
  });

  @action
  public onDeleteSkuClick = (row: GoodsItem) => {
    this.egGridBottomStore.rows = this.egGridBottomStore.rows.filter((item) => item.id !== row.id);
  };

  @action
  public handleClose = () => {
    this.visible = false;
    this.submitCallback = null;
    this.findByConditionsKey = ENUM_FIND_BY_CONDITIONS_KEY.skuNo.value;
    this.findByConditionsValue = '';
    this.egGridTopStore.clearToOriginal();
    this.egGridBottomStore.clearToOriginal();
  };

  private submitCallback?: (rows: GoodsItem[]) => any;

  @action
  public handleOpen = (submitCallback?: SelectGoodsModalStore['submitCallback']) => {
    this.visible = true;
    this.submitCallback = submitCallback;
  };

  @action
  public handleSubmit = () => {
    if (typeof this.submitCallback === 'function') {
      this.submitCallback(this.egGridBottomStore.rows);
    }

    this.handleClose();
  };

  @action
  public onBatchAddClick = () => {
    this.egGridBottomStore.rows = _.uniqBy(this.egGridBottomStore.rows.concat(this.egGridTopStore.selectRows), 'id');
  };

  @action
  public onAddGoodsClick = (row: GoodsItem) => {
    this.egGridBottomStore.rows = _.uniqBy(this.egGridBottomStore.rows.concat(row), 'id');
  };

  @action
  public queryFindByConditions = () => {
    if (!this.findByConditionsValue) {
      message.warning({
        key: '请输入查询内容',
        content: '请输入查询内容',
      });
      return;
    }

    this.egGridTopStore.loading = true;
    request<PaginationData<GoodsItem>>({
      method: 'POST',
      url: '/api/cloud/baseinfo/rest/sku/findByConditions',
      data: {
        [this.findByConditionsKey]: this.findByConditionsValue,
        page: this.egGridTopStore.current,
        pageSize: this.egGridTopStore.pageSize,
      },
    })
      .then((info) => {
        this.egGridTopStore.rows = info?.data?.list;
        this.egGridTopStore.total = info?.data?.totalCount;
      })
      .finally(() => this.egGridTopStore.loading = false);
  };
}

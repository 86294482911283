import { NormalProgramme } from 'egenie-utils';
import type { Store } from './store';
import { ENUM_WAVE_STRATEGY_TYPE } from './types';

export function getNormalProgramme(context: Store) {
  return new NormalProgramme({
    count: 4,
    filterItems: [
      {
        type: 'dateRange',
        field: 'duration',
        label: '日期',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'warehouseId',
        label: '仓库',
      },
      {
        type: 'select',
        field: 'waveType',
        label: '策略类型',
        data: Object.values(ENUM_WAVE_STRATEGY_TYPE),
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'waveId',
        label: '波次策略',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'pickingOrderState',
        label: '拣货状态',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'orderSortedState',
        label: '分拣状态',
        value: [
          '1',
          '2',
        ],
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'pdaUserId',
        label: '拣货员',
      },
      {
        type: 'input',
        field: 'pickingOrderNo',
        label: '波次号',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'ownerId',
        label: '货主',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'wmsPickOrderType',
        label: '波次类型',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'cartsId',
        label: '拣货车',
      },
    ],
    handleSearch: context.mainSubStructureModel.gridModel.onQuery,
  });
}

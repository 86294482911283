import { Button, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './automaticSetting.less';
import type { AutomaticSettingStore } from './automaticSettingStore';
import { SelectGoodsModal } from '../selectGoodsModal/selectGoodsModal';
import { MainSubStructure } from 'egenie-utils';
import { ContentWrapper, TitleInformation } from '../manualSetting/manualSetting';
import { RenderByCondition } from 'egenie-common';
import { ENUM_WAVE_STRATEGY_TYPE } from '../../types';

const ENUM_WMS_ORDER_TYPE = [
  {
    value: '0',
    label: '一单一货',
  },
  {
    value: '1',
    label: '一单多货',
  },
];

export const AutomaticSetting: React.FC<{ automaticSettingStore: AutomaticSettingStore; }> = observer(({ automaticSettingStore }) => {
  const {
    formRef,
    ownerData,
    courierListData,
    specifiedGoodsDisplayStore,
    showAutoProductNum,
    handleShowAutoProductNumChange,
    showAppointCourierList,
    handleShowAppointCourierListChange,
    showAutoAdjacentNum,
    handleShowAutoAdjacentNumChange,
    showAutoSkuNum,
    handleShowAutoSkuNumChange,
    showAutoSkuContainNum,
    handleShowAutoSkuContainNumChange,
    parent: { vendorData },
  } = automaticSettingStore;
  return (
    <>
      <Form ref={formRef}>
        <TitleInformation title="基本信息"/>
        <ContentWrapper>
          <Row>
            <Col span={16}>
              <Form.Item
                className={styles.alignLabel}
                label="策略名称"
                name="waveName"
                rules={[
                  {
                    required: true,
                    message: '请输入策略名称',
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea
                  allowClear
                  autoSize={{
                    minRows: 1,
                    maxRows: 1,
                  }}
                  maxLength={20}
                  placeholder="请输入策略名称"
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
        </ContentWrapper>
        <TitleInformation title="策略方案"/>
        <ContentWrapper>
          <Row>
            <Col span={8}>
              <Form.Item
                className={styles.alignLabel}
                label="发货单类型"
                name="wmsOrderType"
                rules={[
                  {
                    required: true,
                    message: '请选择发货单类型',
                  },
                ]}
              >
                <Radio.Group options={ENUM_WMS_ORDER_TYPE}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                className={styles.alignLabel}
                label="策略类型"
                name="waveType"
                rules={[
                  {
                    required: true,
                    message: '请选择策略类型',
                  },
                ]}
              >
                <Select
                  allowClear
                  options={Object.values(ENUM_WAVE_STRATEGY_TYPE)}
                  placeholder="请选择策略类型"
                  showArrow
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                className={styles.alignLabel}
                label="指定货主"
                name="ownerId"
              >
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  options={ownerData}
                  placeholder="请选择货主"
                  showArrow
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={8}>
            <Form.Item
              label="指定供应商"
              name="appointVendorList"
            >
              <Select
                allowClear
                mode="multiple"
                optionFilterProp="label"
                options={vendorData}
                placeholder="请选择供应商"
                showArrow
              />
            </Form.Item>
          </Col>
          <div>
            <Space size="small">
              <Form.Item colon={false}>
                <Checkbox
                  checked={showAppointCourierList}
                  onChange={handleShowAppointCourierListChange}
                >
                  包含快递公司
                </Checkbox>
              </Form.Item>
              <Form.Item
                colon={false}
                name="appointCourierList"
              >
                <Select
                  allowClear
                  disabled={!showAppointCourierList}
                  mode="multiple"
                  optionFilterProp="label"
                  options={courierListData}
                  placeholder="请选择快递"
                  showArrow
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Space>
          </div>
        </ContentWrapper>
        <TitleInformation title="下面条件必须至少选择一个"/>
        <ContentWrapper>
          <div>
            <Space size="small">
              <Form.Item colon={false}>
                <Checkbox
                  checked={showAutoProductNum}
                  onChange={handleShowAutoProductNumChange}
                >
                  包含相同款的发货单达到
                </Checkbox>
              </Form.Item>
              <Form.Item
                colon={false}
                name="autoProductNum"
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    max: 500,
                    message: '最小为1',
                  },
                ]}
              >
                <InputNumber
                  disabled={!showAutoProductNum}
                  max={500}
                  min={0}
                  placeholder="请输入数量"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                colon={false}
                label="单"
              />
            </Space>
          </div>
          <div>
            <Space size="small">
              <Form.Item colon={false}>
                <Checkbox
                  checked={showAutoAdjacentNum}
                  onChange={handleShowAutoAdjacentNumChange}
                >
                  相邻货架
                </Checkbox>
              </Form.Item>
              <Form.Item
                colon={false}
                name="autoAdjacentNum"
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    max: 500,
                    message: '最小为1',
                  },
                ]}
              >
                <InputNumber
                  disabled={!showAutoAdjacentNum}
                  max={500}
                  min={0}
                  placeholder="请输入数量"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                colon={false}
                label="单"
              />
            </Space>
          </div>
          <div>
            <Space size="small">
              <Form.Item colon={false}>
                <Checkbox
                  checked={showAutoSkuContainNum}
                  onChange={handleShowAutoSkuContainNumChange}
                >
                  包含相同SKU的发货单达到
                </Checkbox>
              </Form.Item>
              <Form.Item
                colon={false}
                name="autoSkuContainNum"
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    max: 500,
                    message: '最小为1',
                  },
                ]}
              >
                <InputNumber
                  disabled={!showAutoSkuContainNum}
                  max={500}
                  min={0}
                  placeholder="请输入数量"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                colon={false}
                label="单"
              />
            </Space>
          </div>
          <div>
            <Space size="small">
              <Form.Item colon={false}>
                <Checkbox
                  checked={showAutoSkuNum}
                  onChange={handleShowAutoSkuNumChange}
                >
                  指定以下商品的发货单达到
                </Checkbox>
              </Form.Item>
              <Form.Item
                colon={false}
                name="autoSkuNum"
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    max: 500,
                    message: '最小为1',
                  },
                ]}
              >
                <InputNumber
                  disabled={!showAutoSkuNum}
                  max={500}
                  min={0}
                  placeholder="请输入数量"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                colon={false}
                label="单"
              />
              <Form.Item
                colon={false}
                label={(
                  <Button
                    disabled={!showAutoSkuNum}
                    onClick={specifiedGoodsDisplayStore.handleChooseGoods}
                  >
                    选择商品
                  </Button>
                )}
              />
            </Space>
          </div>
          <RenderByCondition show={showAutoSkuNum}>
            <div style={{ height: 200 }}>
              <MainSubStructure store={specifiedGoodsDisplayStore.mainSubStructureModel}/>
            </div>
          </RenderByCondition>
        </ContentWrapper>
      </Form>
      <SelectGoodsModal selectGoodsModalStore={specifiedGoodsDisplayStore.selectGoodsModalStore}/>
    </>
  );
});

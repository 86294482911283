import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { ValueAndLabelData, BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import type { StrategyItem } from '../../types';
import type { WaveStrategySettingDetail, WaveStrategySettingBase } from '../types';
import { getCourierEnabled, getOwner } from '../../../../utils';
import type { WaveStrategySettingModalStore } from '../waveStrategySettingModalStore';
import { GoodsDisplayStore } from '../goodsDisplayStore';

export class AutomaticSettingStore implements WaveStrategySettingBase {
  constructor(public parent: WaveStrategySettingModalStore) {
    this.init();
  }

  public specifiedGoodsDisplayStore: GoodsDisplayStore = new GoodsDisplayStore();

  @action private init = () => {
    getOwner()
      .then((owner) => this.ownerData = owner);

    getCourierEnabled()
      .then((courierListData) => this.courierListData = courierListData);
  };

  @observable public formRef: React.RefObject<FormInstance> = React.createRef<FormInstance>();

  @observable public ownerData: ValueAndLabelData = [];

  @observable public courierListData: ValueAndLabelData = [];

  @observable public showAutoProductNum = false;

  @action public handleShowAutoProductNumChange = (event) => {
    this.showAutoProductNum = event.target.checked;
    this.formRef.current.resetFields(['autoProductNum']);
  };

  @observable public showAutoSkuContainNum = false;

  @action public handleShowAutoSkuContainNumChange = (event) => {
    this.showAutoSkuContainNum = event.target.checked;
    this.formRef.current.resetFields(['autoSkuContainNum']);
  };

  @observable public showAppointCourierList = false;

  @action public handleShowAppointCourierListChange = (event) => {
    this.showAppointCourierList = event.target.checked;
    this.formRef.current.resetFields(['appointCourierList']);
  };

  @observable public showAutoAdjacentNum = false;

  @action public handleShowAutoAdjacentNumChange = (event) => {
    this.showAutoAdjacentNum = event.target.checked;
    this.formRef.current.resetFields(['autoAdjacentNum']);
  };

  @observable public showAutoSkuNum = false;

  @action public handleShowAutoSkuNumChange = (event) => {
    this.showAutoSkuNum = event.target.checked;
    this.formRef.current.resetFields(['autoSkuNum']);
    this.specifiedGoodsDisplayStore.mainSubStructureModel.gridModel.clearToOriginal();
  };

  @action
  public handleSubmit = async() => {
    const params = await this.formRef.current.validateFields();
    if (!(params.autoProductNum >= 1 || params.autoAdjacentNum >= 1 || (params.autoSkuNum >= 1 && this.specifiedGoodsDisplayStore.params.length) || params.autoSkuContainNum >= 1)) {
      const error = '请选择必须的条件';
      message.warning({
        key: error,
        content: error,
      });
      throw new Error(error);
    }

    const req = await request<BaseData<StrategyItem>>({
      method: 'POST',
      url: '/api/cloud/wms/rest/wave/strategy/createOrUpdate/autoWave',
      data: {
        ...params,
        id: this.parent.activeStrategyItem?.id,
        waveName: params.waveName.trim(),
        ownerId: _.toString(params.ownerId) || undefined,
        autoProductNum: this.showAutoProductNum ? params.autoProductNum >>> 0 : undefined,
        autoSkuContainNum: this.showAutoSkuContainNum ? params.autoSkuContainNum >>> 0 : undefined,
        appointCourierList: this.showAppointCourierList ? _.toString(params.appointCourierList) || undefined : undefined,
        appointVendorList: _.toString(params.appointVendorList) || undefined,
        autoAdjacentNum: this.showAutoAdjacentNum ? params.autoAdjacentNum >>> 0 : undefined,
        autoSkuNum: this.showAutoSkuNum ? params.autoSkuNum >>> 0 : undefined,

        inAppointedSkuList: this.specifiedGoodsDisplayStore.params.join(','),
      },
    });

    this.parent.activeStrategyItem?.id ? message.success('更新成功') : message.success('创建成功');
    return req?.data;
  };

  @action
  public handleInitSetting = async() => {
    this.formRef.current?.resetFields();
    if (!this.parent.activeStrategyItem?.id) {
      return;
    }

    const { data = {}} = await request<BaseData<WaveStrategySettingDetail>>({ url: `/api/cloud/wms/rest/wave/strategy/${this.parent.activeStrategyItem?.id}` });

    function stringToArray(value: string): string[] {
      return _.toString(value)
        .split(',')
        .filter(Boolean);
    }

    const {
      waveName,
      ownerId,
      inAppointedSkuList,
      autoProductNum,
      appointCourierList,
      appointVendorList,
      autoAdjacentNum,
      autoSkuNum,
      wmsOrderType,
      waveType,
      autoSkuContainNum,
    } = data;

    this.showAutoProductNum = Boolean(autoProductNum > 0);
    this.showAutoSkuContainNum = Boolean(autoSkuContainNum > 0);
    this.showAppointCourierList = !_.isEmpty(appointCourierList);
    this.showAutoAdjacentNum = Boolean(autoAdjacentNum > 0);
    this.showAutoSkuNum = Boolean(autoSkuNum > 0);
    this.specifiedGoodsDisplayStore.handleInit(inAppointedSkuList || []);

    this.formRef.current?.setFieldsValue({
      wmsOrderType: _.toString(wmsOrderType) || undefined,
      waveType: _.toString(waveType) || undefined,
      waveName: waveName || '',
      ownerId: stringToArray(ownerId),
      autoProductNum: this.showAutoProductNum ? autoProductNum : undefined,
      autoSkuContainNum: this.showAutoSkuContainNum ? autoSkuContainNum : undefined,
      autoAdjacentNum: this.showAutoAdjacentNum ? autoAdjacentNum : undefined,
      autoSkuNum: this.showAutoSkuNum ? autoSkuNum : undefined,
      appointCourierList: this.showAppointCourierList ? stringToArray(appointCourierList) : undefined,
      appointVendorList: stringToArray(appointVendorList) || undefined,
    });
  };
}

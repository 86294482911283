import { Button, message, Modal, Progress, Space } from 'antd';
import { formatNumber, printHelper, printWayBill } from 'egenie-common';
import type { BaseData, PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request, TimeStampFormatter } from 'egenie-utils';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { LogTooltip } from '../../utils';
import styles from './index.less';
import type { Store } from './store';
import type { MainItem } from './types';
import { ENUM_WAVE_STRATEGY_TYPE } from './types';

export function getMainSubStructureModel(context: Store) {
  return new MainSubStructureModel({
    pageId: '2668',
    buttons: [
      {
        permissionId: '164',
        text: '移除未分拣发货单',
        icon: 'icon-delete',
        handleClick: () => {
          const wmsPickingOrderId = Array.from(context.mainSubStructureModel.gridModel.selectedIds)
            .join(',');

          if (!wmsPickingOrderId) {
            const error = '请选择数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确定移除吗?',
            onOk: () => request<BaseData<string>>({
              method: 'DELETE',
              url: '/api/cloud/wms/rest/wave/delete/wms/order',
              data: wmsPickingOrderId,
            })
              .then((info) => {
                context.mainSubStructureModel.onQuery();
                message.success(info.data || info.info || '移除成功');
              }),
          });
        },
      },
      {
        permissionId: '705',
        text: '打印面单',
        icon: 'icon-d_print',
        handleClick: () => {
          const ids = context.mainSubStructureModel.gridModel.selectRows.map((item) => item.id)
            .join(',');

          if (!ids) {
            const error = '请选择数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确认打印?',
            onOk: async() => {
              const res = await request<BaseData<{ courierPintData?: any[]; wmsOrderPrintData?: { tempData?: any; userDataList?: any[]; }; }>>({
                url: '/api/cloud/wms/rest/order/picking/getDeliveryPrintData',
                method: 'post',
                data: {
                  userDataIds: ids,
                  tempType: 0,
                  printSrc: 5,
                },
              });

              // 打印发货单
              if (res?.data?.wmsOrderPrintData) {
                printHelper.toggleToLodop();
                printHelper.print({
                  preview: false,
                  templateData: res?.data?.wmsOrderPrintData?.tempData,
                  contents: res?.data?.wmsOrderPrintData?.userDataList,
                });
              }

              await printWayBill.executePrint({
                preview: false,
                tempType: 0,
                printSrc: 5,
              }, res?.data?.courierPintData);
            },
          });
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 200,
          frozen: true,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <>
                <Button
                  disabled={row.wmsPickingOrderState != 0}
                  onClick={() => context.handleDistribute(row, false)}
                  size="small"
                  style={{ paddingLeft: 0 }}
                  type="link"
                >
                  分配拣货员
                </Button>
                <Button
                  disabled={row.wmsPickingOrderState != 1}
                  onClick={() => context.handleDistribute(row, true)}
                  size="small"
                  style={{ paddingLeft: 0 }}
                  type="link"
                >
                  重新分配
                </Button>
                <Button
                  disabled={_.toString(row.waveType) !== ENUM_WAVE_STRATEGY_TYPE.hotWave.value}
                  onClick={() => context.handlePatchExamine(row)}
                  size="small"
                  style={{ paddingLeft: 0 }}
                  type="link"
                >
                  批量验货
                </Button>
              </>
            );
          },
        },
        {
          key: 'wmsPickingOrderNo',
          name: '波次号',
          width: 200,
          formatter: ({ row }: { row: MainItem; }) => (
            <a
              onClick={() => {
                if (row.orderType === 1) {
                  try {
                    window.top.egenie.openTab(`/egenie-cloud-wms/invoice/index?${qs.stringify({ wmsPickingOrderNo: row.wmsPickingOrderNo })}`, 1640, '发货单', 'icon_fhd');
                  } catch (e) {
                    console.log(e);
                  }
                } else if (row.orderType === 2 || row.orderType === 3) {
                  try {
                    window.top.egenie.openTab(`/egenie-cloud-wms/stockOut/index?${qs.stringify({ wmsPickingOrderNo: row.wmsPickingOrderNo })}`, 60013, '出库单', 'icon-icon_ckd');
                  } catch (e) {
                    console.log(e);
                  }
                }
              }}
              type="link"
            >
              {row.wmsPickingOrderNo}
            </a>
          ),
        },
        {
          key: 'waveTypeName',
          name: '策略类型',
          width: 80,
        },
        {
          key: 'waveName',
          name: '波次策略',
          width: 200,
        },
        {
          key: 'wmsPickingOrderStateStr',
          name: '拣货状态',
          width: 100,
        },
        {
          key: 'orderSortedStateStr',
          name: '分拣状态',
          width: 100,
        },
        {
          key: '波次进度',
          name: '波次进度',
          width: 150,
          formatter: ({ row }) => {
            const percent = formatNumber(100 * row.orderPrintedNum / row.orderTotalNum);
            return (
              <Progress
                className={styles.progressInner}
                format={() => `${formatNumber(row.orderPrintedNum)}/${formatNumber(row.orderTotalNum)}`}
                percent={percent}
                status={row.wmsPickingOrderState == 3 ? 'success' : 'active'}
              />
            );
          },
        },
        {
          key: 'pdaUserName',
          name: '拣货员',
          width: 100,
        },
        {
          key: 'wmsOrderDetailNum',
          name: 'sku数量',
          width: 80,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          key: '日志',
          name: '日志',
          width: 50,
          frozen: true,
          formatter: ({ row }) => (
            <LogTooltip
              children={(
                <a>
                  详情
                </a>
              )}
              entityId={row.id}
              height={230}
              logBizModuleCode={70000}
              popoverProps={{
                title: () => (
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    波次日志
                  </div>
                ),
                trigger: 'click',
                placement: 'left',
              }}
              width={810}
            />
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/waveManage/index',
    },
    btnExtraRight: (
      <Button
        onClick={() => context.paramsSettingModalStore.handleOpen()}
        type="link"
      >
        参数设置
      </Button>
    ),
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/order/picking/list',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}

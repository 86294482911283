import type { FormInstance } from 'antd';
import { TimePicker, Form, Modal, Switch, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

interface Params {
  openFlag: boolean;
  executeTime: string;
}

const format = 'HH:mm:ss';

export class ParamsSettingModalStore {
  public formRef = React.createRef<FormInstance>();

  @observable public isLoading = false;

  @observable public visible = false;

  @action public handleOpen = () => {
    this.visible = true;
    request<BaseData<Params>>({
      url: '/api/cloud/wms/rest/time/job/wave/query',
      method: 'post',
    })
      .then((info) => {
        this.openFlag = Boolean(info?.data?.openFlag);
        this.formRef.current?.setFieldsValue({
          openFlag: Boolean(info?.data?.openFlag),
          executeTime: info?.data?.executeTime ? moment(info?.data?.executeTime, format) : undefined,
        });
      });
  };

  @action public handleClose = () => {
    this.visible = false;
  };

  @observable public openFlag = false;

  @action public handleOpenFlag = (openFlag: boolean): void => {
    this.openFlag = openFlag;
  };

  @action public handleFinish = (data: Omit<Params, 'executeTime'> & { executeTime: moment.Moment; }) => {
    this.isLoading = true;
    request({
      url: '/api/cloud/wms/rest/time/job/wave/create',
      method: 'POST',
      data: {
        ...data,
        executeTime: data?.executeTime?.format(format),
      },
    })
      .then(() => {
        message.success('创建成功');
        this.handleClose();
      })
      .finally(() => this.isLoading = false);
  };
}

@observer
export class ParamsSettingModal extends React.Component<{ paramsSettingModalStore: ParamsSettingModalStore; }> {
  render() {
    const {
      formRef,
      visible,
      handleClose,
      isLoading,
      handleFinish,
      openFlag,
      handleOpenFlag,
    } = this.props.paramsSettingModalStore;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleClose}
        onOk={() => formRef?.current?.submit()}
        title="参数设置"
        visible={visible}
        width={400}
      >
        <Form
          labelCol={{ span: 12 }}
          layout="horizontal"
          onFinish={handleFinish}
          ref={formRef}
          wrapperCol={{ span: 12 }}
        >
          <Form.Item
            label="是否开启每日定时波次"
            name="openFlag"
            valuePropName="checked"
          >
            <Switch onChange={handleOpenFlag}/>
          </Form.Item>
          <Form.Item
            label="每日定时波次时间"
            name="executeTime"
            rules={[
              {
                required: openFlag,
                message: '请选择时间',
              },
            ]}
          >
            <TimePicker
              format={format}
              placeholder="请选择时间"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

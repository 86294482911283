import { Button, Card, Layout, Popover, Statistic, Typography } from 'antd';
import { formatNumber, RenderByCondition } from 'egenie-common';
import { NormalProgrammeComponent, MainSubStructure, Permission } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { ParamsSettingModal } from './paramsSettingModal';
import { Store } from './store';
import { WaveStrategySettingModal } from './waveStrategySettingModal/waveStrategySettingModal';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  return (
    <Provider store={store}>
      <Layout className={styles.container}>
        <Layout.Sider
          theme="light"
          width="300"
        >
          <Strategy/>
        </Layout.Sider>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={store.normalProgramme}/>
          </Card>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={store.mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <StrategyModal/>
      <ParamsSettingModal paramsSettingModalStore={store.paramsSettingModalStore}/>
    </Provider>
  );
}

@inject('store')
@observer
class Strategy extends React.Component<{ store?: Store; }> {
  render() {
    const {
      strategyList,
      handleAddStrategy,
      handleGenerateWave,
      handleEditStrategy,
      handleDeleteStrategy,
      handleRefreshOrderCount,
      countDownTime,
      countDownTimeFinish,
      handleGenerateAllWaveStrategy,
    } = this.props.store;
    return (
      <div className={styles.strategy}>
        <div className={styles.title}>
          波次策略
          <Statistic.Countdown
            format="自动刷新s"
            onFinish={countDownTimeFinish}
            title=""
            value={countDownTime}
          />
        </div>
        <div className={styles.content}>
          {
            strategyList.map((item, index) => (
              <section key={item.id}>
                <section className={styles.index}>
                  {index > 8 ? index + 1 : `0${index + 1}`}
                </section>
                <Permission permissionId="2668_709">
                  <Button
                    className={styles.refresh}
                    loading={item.loading}
                    onClick={() => handleRefreshOrderCount(item)}
                    type="link"
                  >
                    刷新
                  </Button>
                </Permission>
                <section className={styles.name}>
                  <Typography.Text
                    ellipsis
                    title={item.waveName}
                  >
                    {item.waveName}
                  </Typography.Text>
                </section>
                <section className={styles.desc}>
                  <RenderByCondition show={!item.auto}>
                    <>
                      <Typography.Text ellipsis>
                        {formatNumber(item.orderNumber)}
                      </Typography.Text>
                      <span>
                        单
                      </span>
                      <span>
                        (待创建波次)
                      </span>
                    </>
                  </RenderByCondition>
                </section>
                <section className={styles.btn}>
                  <div className={styles.left}>
                    {
                      item.auto ? (
                        <Button
                          disabled
                          onClick={() => handleGenerateWave(item)}
                          type="link"
                        >
                            &nbsp;自动生成波次
                        </Button>
                      ) : (
                        <Permission permissionId="2668_30">
                          <Button
                            icon={<i className="icon-replace"/>}
                            onClick={() => handleGenerateWave(item)}
                            type="link"
                          >
                            &nbsp;创建波次
                          </Button>
                        </Permission>
                      )
                    }
                    <span/>
                  </div>
                  <div className={styles.right}>
                    <Popover
                      arrowPointAtCenter
                      content={(
                        <>
                          <Permission permissionId="2668_711">
                            <>
                              <Button
                                onClick={() => handleDeleteStrategy(item)}
                                type="link"
                              >
                                删除
                              </Button>
                              <br/>
                            </>
                          </Permission>
                          <Permission permissionId="2668_710">
                            <Button
                              onClick={() => handleEditStrategy(item)}
                              type="link"
                            >
                              编辑
                            </Button>
                          </Permission>
                        </>
                      )}
                      trigger="click"
                    >
                      <span className={styles.symbol}>
                        ...
                      </span>
                    </Popover>
                  </div>
                </section>
              </section>
            ))
          }
        </div>
        <div className={styles.bottom}>
          <Permission permissionId="2668_708">
            <Button
              className="egenie-secondary-content"
              onClick={handleAddStrategy}
              type="primary"
            >
              新建波次策略
            </Button>
          </Permission>
          <Button
            onClick={handleGenerateAllWaveStrategy}
            type="primary"
          >
            一键生成波次
          </Button>
        </div>
      </div>
    );
  }
}

@inject('store')
@observer
class StrategyModal extends React.Component<{ store?: Store; }> {
  render() {
    const {
      isShowStrategy,
      activeStrategyItem,
      handleCloseStrategy,
      handleAddOrEditCallback,
    } = this.props.store;
    return (
      <RenderByCondition show={isShowStrategy}>
        <WaveStrategySettingModal
          activeStrategyItem={activeStrategyItem}
          callback={handleAddOrEditCallback}
          onCancel={handleCloseStrategy}
        />
      </RenderByCondition>
    );
  }
}


import type React from 'react';
import { getAllVendor } from '../../../utils';
import type { VendorItem } from '../../../utils';
import type { WaveStrategySettingModalProps } from './waveStrategySettingModal';
import { ManualSettingStore } from './manualSetting/manualSettingStore';
import { action, observable } from 'mobx';
import { AutomaticSettingStore } from './automaticSetting/automaticSettingStore';
import type { StrategyItem } from '../types';

export const ENUM_WAVE_STRATEGY_TYPE = {
  manualSetting: {
    value: 'manualSetting',
    label: '手动生成波次策略',
  },
  automaticSetting: {
    value: 'automaticSetting',
    label: '自动生成波次策略',
  },
};

export class WaveStrategySettingModalStore {
  constructor(props: WaveStrategySettingModalProps) {
    this.activeStrategyItem = props.activeStrategyItem;
    this.callback = props.callback;
    this.onCancel = props.onCancel;

    this.manualSettingStore = new ManualSettingStore(this);
    this.automaticSettingStore = new AutomaticSettingStore(this);
    this.handleActiveWaveStrategyTypeChange(this.activeStrategyItem?.auto ? ENUM_WAVE_STRATEGY_TYPE.automaticSetting.value : ENUM_WAVE_STRATEGY_TYPE.manualSetting.value);
    getAllVendor()
      .then((vendorData) => this.vendorData = vendorData);
  }

  public callback: WaveStrategySettingModalProps['callback'] = null;

  private onCancel: WaveStrategySettingModalProps['onCancel'] = null;

  public activeStrategyItem: StrategyItem = null;

  public manualSettingStore: ManualSettingStore;

  public automaticSettingStore: AutomaticSettingStore;

  @observable public vendorData: VendorItem[] = [];

  @observable public isSubmit = false;

  @action public handleSubmit = () => {
    this.isSubmit = true;
    switch (this.activeWaveStrategyType) {
      case ENUM_WAVE_STRATEGY_TYPE.manualSetting.value:
        this.manualSettingStore.handleSubmit()
          .then((strategyItem) => {
            this.callback(strategyItem);
            this.onCancel();
          })
          .finally(() => this.isSubmit = false);
        break;
      case ENUM_WAVE_STRATEGY_TYPE.automaticSetting.value:
        this.automaticSettingStore.handleSubmit()
          .then((strategyItem) => {
            this.callback(strategyItem);
            this.onCancel();
          })
          .finally(() => this.isSubmit = false);
        break;
    }
  };

  @observable public activeWaveStrategyType: string = null;

  @action public handleActiveWaveStrategyTypeChange = (activeWaveStrategyType: string) => {
    if (activeWaveStrategyType && activeWaveStrategyType !== this.activeWaveStrategyType) {
      this.activeWaveStrategyType = activeWaveStrategyType;
      switch (activeWaveStrategyType) {
        case ENUM_WAVE_STRATEGY_TYPE.manualSetting.value:
          this.manualSettingStore.handleInitSetting();
          break;
        case ENUM_WAVE_STRATEGY_TYPE.automaticSetting.value:
          this.automaticSettingStore.handleInitSetting();
          break;
      }
    }
  };
}

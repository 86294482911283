import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import React from 'react';
import type { StrategyItem } from '../../types';
import type { WaveStrategySettingDetail, WaveStrategySettingBase } from '../types';
import { ENUM_PLUS_SERVICE_TYPE, getCourierEnabled, getOwner, getShopList, getWarehouse } from '../../../../utils';
import type { WaveStrategySettingModalStore } from '../waveStrategySettingModalStore';
import { GoodsDisplayStore } from '../goodsDisplayStore';
import { ENUM_WAVE_STRATEGY_TYPE } from '../../types';

export class ManualSettingStore implements WaveStrategySettingBase {
  constructor(public parent: WaveStrategySettingModalStore) {
    this.init();
  }

  public specifiedGoodsDisplayStore: GoodsDisplayStore = new GoodsDisplayStore();

  public excludeGoodsDisplayStore: GoodsDisplayStore = new GoodsDisplayStore();

  @action private init = () => {
    request<Array<{
      name: string;
      code: string;
      type: string;
    }>>({ url: '/api/cloud/baseinfo/rest/common/dict?type=wms_order_type' })
      .then((wmsOrderTypeData) => this.wmsOrderTypeData = (wmsOrderTypeData || []).map((item) => ({
        label: item.name,
        value: String(item.code),
      })));

    request<DictData>({ url: '/api/cloud/wms/rest/wave/strategy/order/mark' })
      .then((info) => this.originOrderMarksData = objToDict(info));

    getOwner()
      .then((owner) => this.ownerData = owner);

    getShopList()
      .then((shopListData) => this.shopListData = shopListData);

    getWarehouse()
      .then((warehouseData) => this.warehouseData = warehouseData);

    getCourierEnabled()
      .then((courierListData) => this.courierListData = courierListData);

    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/platform/type' })
      .then((info) => {
        this.platformTypeData = objToDict(info.data);
      });
  };

  @observable public formRef: React.RefObject<FormInstance> = React.createRef<FormInstance>();

  @observable public waveType: string = undefined;

  @action public handleWaveTypeChange = (waveType: string | number): void => {
    this.waveType = _.toString(waveType) || undefined;
    if (this.orderMarksDisabled) {
      this.formRef.current?.setFieldsValue({ orderMarks: undefined });
    }
  };

  @computed public get orderMarksDisabled(): boolean {
    return this.waveType === ENUM_WAVE_STRATEGY_TYPE.hotWave.value;
  }

  @observable public wmsOrderTypeData: ValueAndLabelData = [];

  @observable public originOrderMarksData: ValueAndLabelData = [];

  @observable public orderMarks: string[] = [];

  @action public handleOrderMarksChange = (orderMarks: string[]) => {
    this.orderMarks = Array.isArray(orderMarks) ? orderMarks : [];
  };

  // 订单标记需要互斥数据(换领标、放合格证、烫印为一组,其他为一组)
  @computed
  public get realOrderMarkData(): ValueAndLabelData {
    const oneGroupData = [
      ENUM_PLUS_SERVICE_TYPE.changeTheTag.value,
      ENUM_PLUS_SERVICE_TYPE.certificate.value,
      ENUM_PLUS_SERVICE_TYPE.ironPrint.value,
      ENUM_PLUS_SERVICE_TYPE.waterWashingLabel.value,
      ENUM_PLUS_SERVICE_TYPE.changeCollarLabel.value,
    ];

    if (this.orderMarks.length === 0) {
      return this.originOrderMarksData;
    }

    return this.originOrderMarksData.filter((item) => {
      return _.intersection(this.orderMarks, oneGroupData).length ? oneGroupData.includes(item.value) : !oneGroupData.includes(item.value);
    });
  }

  @observable public ownerData: ValueAndLabelData = [];

  @observable public shopListData: ValueAndLabelData = [];

  @observable public courierListData: ValueAndLabelData = [];

  @observable public warehouseData: ValueAndLabelData = [];

  @observable public platformTypeData: ValueAndLabelData = [];

  @action
  public handleWarehouseChange = () => {
    const {
      getFieldValue,
      setFieldsValue,
    } = this.formRef.current;
    if (!getFieldValue('appointWarehouseList')) {
      setFieldsValue({
        appointWarehouseAreaList: undefined,
        appointWarehouseShelfList: undefined,
        appointWarehouseBinList: undefined,
      });
      this.warehouseShelfData = [];
      this.warehouseBinData = [];
      this.warehouseAreaData = [];
    }
    this.getWarehouseAreaData(getFieldValue('appointWarehouseList'));
  };

  @observable public warehouseAreaData: ValueAndLabelData = [];

  @action
  public handleWarehouseAreaChange = () => {
    const {
      getFieldValue,
      setFieldsValue,
    } = this.formRef.current;
    if (!getFieldValue('appointWarehouseAreaList') || getFieldValue('appointWarehouseAreaList').length === 0 || getFieldValue('appointWarehouseAreaList').length > 1) {
      setFieldsValue({
        appointWarehouseShelfList: undefined,
        appointWarehouseBinList: undefined,
      });
      this.warehouseBinData = [];
      this.warehouseShelfData = [];
      return;
    }
    if (getFieldValue('appointWarehouseAreaList').length === 1) {
      this.warehouseShelfDisabled = false;
      this.warehouseBinDisabled = false;
    }
    this.getWarehouseShelfData(getFieldValue('appointWarehouseAreaList'), getFieldValue('appointWarehouseList'));
  };

  @action
  public getWarehouseAreaData = (warehouseId: string) => {
    request<BaseData<Array<{ warehouseAreaName: string; id: number; }>>>({
      method: 'POST',
      url: '/api/cloud/baseinfo/rest/warehouse/area/get',
      data: {
        warehouseId: warehouseId || '',
        warehouseAreaType: '1,10',
      },
    })
      .then((info) => this.warehouseAreaData = (info.data || []).map((item) => ({
        label: item.warehouseAreaName,
        value: String(item.id),
      })));
  };

  @action
  public handleWarehouseAreaBlur = () => {
    let params: { appointWarehouseAreaList?: number[]; appointWarehouseShelfList?: number[]; } = {};
    if (this.formRef.current) {
      params = this.formRef.current.getFieldsValue();
    }
    if (params.appointWarehouseAreaList && params.appointWarehouseAreaList.length > 1) {
      this.warehouseShelfDisabled = true;
      this.warehouseBinDisabled = true;
    } else {
      this.warehouseShelfDisabled = false;
      this.warehouseBinDisabled = false;
    }
  };

  @observable public warehouseShelfData: ValueAndLabelData = [];

  @observable public warehouseShelfDisabled = false;

  @action
  public getWarehouseShelfData = (warehouseAreaId: string[], warehouseId: string) => {
    request<BaseData<Array<{ warehouseShelfName: string; id: string; }>>>({
      method: 'POST',
      url: '/api/cloud/wms/rest/warehouse/shelf/get',
      data: {
        warehouseAreaId: (warehouseAreaId || []).join(','),
        warehouseId,
      },
    })
      .then((info) => this.warehouseShelfData = (info.data || []).map((item) => ({
        label: item.warehouseShelfName,
        value: String(item.id),
      })));
  };

  @action
  public handleWarehouseShelfChange = () => {
    const {
      getFieldsValue,
      setFieldsValue,
      getFieldValue,
    } = this.formRef.current;
    const params = getFieldsValue();
    if (!params.appointWarehouseShelfList || params.appointWarehouseShelfList.length === 0 || params.appointWarehouseShelfList.length > 1) {
      this.warehouseBinData = [];
      setFieldsValue({ appointWarehouseBinList: undefined });
    } else {
      if (getFieldValue('appointWarehouseShelfList').length === 1) {
        this.warehouseBinDisabled = false;
      }

      request<BaseData<Array<{ serialNo: string; id: number; }>>>({
        method: 'POST',
        url: '/api/cloud/baseinfo/rest/warehouse/bin/get',
        data: {
          warehouseId: params.appointWarehouseList,
          warehouseAreaId: params.appointWarehouseAreaList && params.appointWarehouseAreaList.join(','),
          warehouseShelfId: params.appointWarehouseShelfList && params.appointWarehouseShelfList.join(','),
        },
      })
        .then((info) => this.warehouseBinData = (info.data || []).map((item) => ({
          label: item.serialNo,
          value: String(item.id),
        })));
    }
  };

  @action
  public handleWarehouseShelfBlur = () => {
    let params: { appointWarehouseAreaList?: number[]; appointWarehouseShelfList?: number[]; } = {};
    if (this.formRef.current) {
      params = this.formRef.current.getFieldsValue();
    }
    this.warehouseBinDisabled = params.appointWarehouseShelfList && params.appointWarehouseShelfList.length > 1;
  };

  @observable public warehouseBinData: ValueAndLabelData = [];

  @observable public warehouseBinDisabled = false;

  @action
  public handleSubmit = async() => {
    const params = await this.formRef.current.validateFields();
    const url: string = this.parent.activeStrategyItem?.id ? '/api/cloud/wms/rest/wave/strategy/update' : '/api/cloud/wms/rest/wave/strategy/create';

    const req = await request<BaseData<StrategyItem>>({
      method: 'POST',
      url,
      data: {
        ...params,
        orderMarks: _.toString(params.orderMarks) || undefined,
        ownerId: _.toString(params.ownerId) || undefined,
        appointShopList: _.toString(params.appointShopList) || undefined,
        appointVendorList: _.toString(params.appointVendorList) || undefined,
        appointCourierList: _.toString(params.appointCourierList) || undefined,
        appointWarehouseAreaList: _.toString(params.appointWarehouseAreaList) || undefined,
        appointWarehouseShelfList: _.toString(params.appointWarehouseShelfList) || undefined,
        appointWarehouseBinList: _.toString(params.appointWarehouseBinList) || undefined,
        waveName: params.waveName.trim(),
        id: this.parent.activeStrategyItem?.id,
        inRemovedSkuList: this.excludeGoodsDisplayStore.params.join(','),
        inAppointedSkuList: this.specifiedGoodsDisplayStore.params.join(','),
        platformType: _.toString(params.platformType) || undefined,
      },
    });

    this.parent.activeStrategyItem?.id ? message.success('更新成功') : message.success('创建成功');
    return req?.data;
  };

  @action
  public handleInitSetting = async() => {
    this.formRef.current?.resetFields();
    if (!this.parent.activeStrategyItem?.id) {
      return;
    }

    const { data = {}} = await request<BaseData<WaveStrategySettingDetail>>({ url: `/api/cloud/wms/rest/wave/strategy/${this.parent.activeStrategyItem?.id}` });

    function stringToArray(value: string): string[] {
      return _.toString(value)
        .split(',')
        .filter(Boolean);
    }

    const {
      waveName,
      wmsOrderType,
      orderMarks,
      orderLimitNumber,
      ownerId,
      sellerMemo,
      tradeMemo,
      appointShopList,
      appointCourierList,
      appointWarehouseAreaList,
      appointWarehouseList,
      appointWarehouseShelfList,
      appointWarehouseBinList,
      appointVendorList,
      inAppointedSkuList,
      inRemovedSkuList,
      waveType,
      platformType,
    } = data;

    this.specifiedGoodsDisplayStore.handleInit(inAppointedSkuList || []);
    this.excludeGoodsDisplayStore.handleInit(inRemovedSkuList || []);
    this.orderMarks = stringToArray(orderMarks);

    this.formRef.current?.setFieldsValue({
      waveName: waveName || '',
      wmsOrderType: _.toString(wmsOrderType) || undefined,
      orderLimitNumber,
      ownerId: stringToArray(ownerId),
      sellerMemo,
      tradeMemo,
      appointShopList: stringToArray(appointShopList),
      appointVendorList: stringToArray(appointVendorList),
      orderMarks: this.orderMarks,
      appointCourierList: stringToArray(appointCourierList),
      appointWarehouseShelfList: stringToArray(appointWarehouseShelfList),
      appointWarehouseBinList: stringToArray(appointWarehouseBinList),
      appointWarehouseList: _.toString(appointWarehouseList) || undefined,
      waveType: _.toString(waveType) || undefined,
      appointWarehouseAreaList: stringToArray(appointWarehouseAreaList),
      platformType: stringToArray(platformType),
    });

    this.handleWaveTypeChange(waveType);

    if (this.formRef.current) {
      this.handleWarehouseChange();
      this.handleWarehouseAreaChange();
      this.handleWarehouseShelfChange();
      this.handleWarehouseShelfBlur();
      this.handleWarehouseAreaBlur();
    }
  };
}

import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './manualSetting.less';
import type { ManualSettingStore } from './manualSettingStore';
import { SelectGoodsModal } from '../selectGoodsModal/selectGoodsModal';
import { egeniePcTheme } from 'egenie-common';
import { MainSubStructure } from 'egenie-utils';
import { ENUM_WAVE_STRATEGY_TYPE } from '../../types';

export const TitleInformation: React.FC<{ title: string; }> = ({ title }) => {
  return (
    <h3
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: egeniePcTheme.spacing['egenie-spacing-md'],
      }}
    >
      <span
        style={{
          width: 4,
          height: 14,
          backgroundColor: egeniePcTheme.color['egenie-primary-color'],
          marginRight: egeniePcTheme.spacing['egenie-spacing-xs'],
        }}
      />
      {title}
    </h3>
  );
};

export const ContentWrapper: React.FC = ({ children }) => {
  return (
    <div style={{ paddingLeft: egeniePcTheme.spacing['egenie-spacing-xs'] }}>
      {children}
    </div>
  );
};

export const ManualSetting: React.FC<{ manualSettingStore: ManualSettingStore; }> = observer(({ manualSettingStore }) => {
  const {
    formRef,
    wmsOrderTypeData,
    handleOrderMarksChange,
    realOrderMarkData,
    ownerData,
    shopListData,
    courierListData,
    warehouseData,
    handleWarehouseChange,
    warehouseAreaData,
    handleWarehouseAreaBlur,
    handleWarehouseAreaChange,
    warehouseShelfData,
    warehouseShelfDisabled,
    handleWarehouseShelfChange,
    handleWarehouseShelfBlur,
    warehouseBinDisabled,
    warehouseBinData,
    specifiedGoodsDisplayStore,
    excludeGoodsDisplayStore,
    handleWaveTypeChange,
    orderMarksDisabled,
    platformTypeData,
    parent: { vendorData },
  } = manualSettingStore;
  return (
    <div className={styles.manualSettingContainer}>
      <Form ref={formRef}>
        <TitleInformation title="基本信息"/>
        <ContentWrapper>
          <Row>
            <Col span={16}>
              <Form.Item
                label="策略名称"
                name="waveName"
                rules={[
                  {
                    required: true,
                    message: '请输入策略名称',
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea
                  allowClear
                  autoSize={{
                    minRows: 1,
                    maxRows: 1,
                  }}
                  maxLength={20}
                  placeholder="请输入策略名称"
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="发货单类型"
                name="wmsOrderType"
              >
                <Select
                  allowClear
                  options={wmsOrderTypeData}
                  placeholder="请选择发货单类型"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="策略类型"
                name="waveType"
              >
                <Select
                  allowClear
                  onChange={handleWaveTypeChange}
                  options={Object.values(ENUM_WAVE_STRATEGY_TYPE)}
                  placeholder="请选择策略类型"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="增值服务"
                name="orderMarks"
              >
                <Select
                  allowClear
                  disabled={orderMarksDisabled}
                  mode="multiple"
                  onChange={handleOrderMarksChange}
                  options={realOrderMarkData}
                  placeholder="请选择增值服务"
                  showArrow
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            className={styles.goodsDisplayContainer}
            style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xss'] }}
          >
            <span>
              指定商品:
            </span>
            <Button onClick={specifiedGoodsDisplayStore.handleChooseGoods}>
              选择商品
            </Button>
            <Button
              className={styles.batchDeleteButton}
              disabled={specifiedGoodsDisplayStore.batchDeleteGoodsDisable}
              onClick={specifiedGoodsDisplayStore.handleBatchDeleteGoods}
            >
              批量删除
            </Button>
          </div>
          <div style={{ height: 200 }}>
            <MainSubStructure store={specifiedGoodsDisplayStore.mainSubStructureModel}/>
          </div>
          <div
            className={styles.goodsDisplayContainer}
            style={{
              marginTop: egeniePcTheme.spacing['egenie-spacing-xs'],
              marginBottom: egeniePcTheme.spacing['egenie-spacing-xss'],
            }}
          >
            <span>
              排除商品:
            </span>
            <Button
              onClick={excludeGoodsDisplayStore.handleChooseGoods}
            >
              选择商品
            </Button>
            <Button
              className={styles.batchDeleteButton}
              disabled={excludeGoodsDisplayStore.batchDeleteGoodsDisable}
              onClick={excludeGoodsDisplayStore.handleBatchDeleteGoods}
            >
              批量删除
            </Button>
          </div>
          <div
            style={{
              height: 200,
              marginBottom: egeniePcTheme.spacing['egenie-spacing-lg'],
            }}
          >
            <MainSubStructure store={excludeGoodsDisplayStore.mainSubStructureModel}/>
          </div>
          <Row>
            <Col span={16}>
              <Form.Item
                label="客服备注"
                name="sellerMemo"
              >
                <Input.TextArea
                  allowClear
                  autoSize={{
                    minRows: 1,
                    maxRows: 4,
                  }}
                  maxLength={1000}
                  placeholder="手动输入内容，多个内容用“;”进行分割文本限制1000字符"
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                label="订单备注"
                name="tradeMemo"
              >
                <Input.TextArea
                  allowClear
                  autoSize={{
                    minRows: 1,
                    maxRows: 4,
                  }}
                  maxLength={1000}
                  placeholder="手动输入内容，多个内容用“;”进行分割文本限制1000字符"
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                initialValue={500}
                label="订单数量"
                name="orderLimitNumber"
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    message: '最小为1',
                  },
                ]}
              >
                <InputNumber
                  max={500}
                  min={0}
                  placeholder="请输入订单数量"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="指定货主"
                name="ownerId"
              >
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  options={ownerData}
                  placeholder="请选择货主"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="指定店铺"
                name="appointShopList"
              >
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  options={shopListData}
                  placeholder="请选择店铺"
                  showArrow
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="指定快递"
                name="appointCourierList"
              >
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  options={courierListData}
                  placeholder="请选择快递"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="指定仓库"
                name="appointWarehouseList"
                rules={[
                  {
                    required: true,
                    message: '请选择仓库',
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={handleWarehouseChange}
                  optionFilterProp="label"
                  options={warehouseData}
                  placeholder="请选择指定仓库"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="指定库区"
                name="appointWarehouseAreaList"
                rules={[
                  {
                    required: true,
                    message: '请选择库区',
                  },
                ]}
              >
                <Select
                  allowClear
                  mode="multiple"
                  onBlur={handleWarehouseAreaBlur}
                  onChange={handleWarehouseAreaChange}
                  optionFilterProp="label"
                  options={warehouseAreaData}
                  placeholder="请选择指定库区"
                  showArrow
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="指定货架"
                name="appointWarehouseShelfList"
              >
                <Select
                  allowClear
                  disabled={warehouseShelfDisabled}
                  mode="multiple"
                  onBlur={handleWarehouseShelfBlur}
                  onChange={handleWarehouseShelfChange}
                  optionFilterProp="label"
                  options={warehouseShelfData}
                  placeholder="请选择货架"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="指定库位"
                name="appointWarehouseBinList"
              >
                <Select
                  allowClear
                  disabled={warehouseBinDisabled}
                  mode="multiple"
                  optionFilterProp="label"
                  options={warehouseBinData}
                  placeholder="请选择库位"
                  showArrow
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="平台类型"
                name="platformType"
              >
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  options={platformTypeData}
                  placeholder="请选择平台"
                  showArrow
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="指定供应商"
                name="appointVendorList"
              >
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  options={vendorData}
                  placeholder="请选择供应商"
                  showArrow
                />
              </Form.Item>
            </Col>
          </Row>
        </ContentWrapper>
      </Form>
      <SelectGoodsModal selectGoodsModalStore={specifiedGoodsDisplayStore.selectGoodsModalStore}/>
      <SelectGoodsModal selectGoodsModalStore={excludeGoodsDisplayStore.selectGoodsModalStore}/>
    </div>
  );
});

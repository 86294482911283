import { Form, Modal, Select } from 'antd';
import { objToDict } from 'egenie-common';
import type { DictData } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import React from 'react';

interface DistributionModalProps {
  callback: (params: { pdaUserId: string | number; }) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
}

export function DistributionModal({
  callback,
  onCancel,
}: DistributionModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  const [
    data,
    setData,
  ] = React.useState<ValueAndLabelData>([]);
  React.useEffect(() => {
    request<DictData>({
      url: '/api/cloud/wms/rest/order/picking/get/user/picker',
      params: { pickerAll: 0 },
    })
      .then((info) => setData(objToDict(info)));
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="分配拣货员"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="拣货员"
          name="pdaUserId"
          rules={[
            {
              required: true,
              message: '请选择拣货员',
            },
          ]}
        >
          <Select
            allowClear
            optionFilterProp="label"
            options={data}
            placeholder="请选择"
            showSearch
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
